import {Line} from 'vue-chartjs'

export default {
  extends: Line,
  mounted() {

    setTimeout(async() => {

      let dailySaleLabels = await JSON.parse(localStorage.getItem("dailySaleLabels"))
      let dailySaleValues = await JSON.parse(localStorage.getItem( "dailySaleValues"))

      // eslint-disable-next-line no-console
      console.log(dailySaleValues)

      this.renderChart({
        labels: dailySaleLabels != null && dailySaleLabels.length > 0 ? dailySaleLabels : [],
        datasets: [
          {
            label: 'Sales',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#D15A1B',
            borderColor: '#D15A1B',
            borderCapStyle: 'round',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#D15A1B',
            pointBackgroundColor: '#ffffff',
            pointBorderWidth: 2,
            pointHoverRadius: 10,
            pointHoverBackgroundColor: '#D15A1B',
            pointHoverBorderColor: '#D15A1B',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: dailySaleValues != null && dailySaleValues.length > 0 ? dailySaleValues : [],
          }
        ]
      }, {responsive: true, maintainAspectRatio: false})

    }, 10)

  }
}
